import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import './ProductCard.css';
import { eatInPrices } from '../../utils/Utils';

export default function ProductCard({ product: { name, price, picture, persons, sku }, endPoint, choice }) {
  const defaultPic = picture ? `/products/${picture}` : '/default.png';

  const personIcons = [];
  for (let i = 0; i < persons; i++) {
    personIcons.push(<PersonIcon key={i} fontSize='small' />);
  }

  let finalPrice = price;

  // Apply eat-in price if choice is "eatin" and the item has a different price for eat-in
  if (choice === "eatin" && eatInPrices[sku]) {
    finalPrice = eatInPrices[sku];
  }

  // Format the final price
  finalPrice = Math.ceil(finalPrice * 100) / 100;
  finalPrice = finalPrice.toFixed(2);

  return (
    <div className="food-card" data-aos={`zoom-in`} data-aos-duration="1000">
      <div className="food-card-logo-container">
        <img className="logo" src={`${endPoint}logo-w.svg`} alt="Logo" />
        <div className='persons-number'>
          {/* {personIcons} */}
        </div>
      </div>
      <div className="food-card-img-container">
        <img src={`${endPoint}${defaultPic}`} alt="Product" />
        <div className="food-card-title-container">
          <h3 className="food-card-title">{name}</h3>
          <p className="food-card-price">₪{finalPrice}</p>
        </div>
      </div>
    </div>
  );
}