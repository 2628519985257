// import './Home.css'
import { BottomNavbar,Footer,Hero, Navbar, ProductsArea, CategoriesSlider } from '../components'

const Home = ({endPoint, contentLng, choice}) => {

    return (
        <div className='Home'>
            <Navbar endPoint={endPoint} />
            <Hero endPoint={endPoint} heroBg={'/cover.jpeg'} mt='0px' textInHero={`${choice} Menu`}/>
            <CategoriesSlider contentLng={contentLng} endPoint={endPoint} />
            <ProductsArea contentLng={contentLng} endPoint={endPoint} choice={choice} />
            <Footer contentLng={contentLng} />
            <BottomNavbar />
        </div>

    )
}

export default Home