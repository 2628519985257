import { RiWhatsappFill } from 'react-icons/ri';
import { FaShare } from 'react-icons/fa';
import { bizNUM, url } from '../../utils/Utils'
import './ProductModal.css'
import { Link } from 'react-router-dom';

const ProductModal = ({ product: { name, price, picture, desc, sku, category, persons, mPrice, bPrice, xbPrice }, endPoint, onClose, contentLng: { whatsappDetails: { wspMsgStart, shareBtn, orderBtn, meal, s, m, b, xb, sNoBurger, bNoBurger } } }) => {

  const defaultPic = picture ? `/products/${picture}` : '/default.png';
  console.log(category);
  const notIndividualPrices = (
    <div className='notIndividualPrices'>
      {persons >= 1 && 
        <a className="product-price p1" href={`https://api.whatsapp.com/send?phone=${bizNUM}&text=${wspMsgStart} ${meal} ${name} ${s}.`}>
          ₪{price}<br/>{category === 'בורגרים 🍔' ? s : sNoBurger}
        </a>
      }
  
  {persons >= 3 && 
    <a className="product-price p3" href={`https://api.whatsapp.com/send?phone=${bizNUM}&text=${wspMsgStart} ${meal} ${name} ${m}.`}>
      ₪{mPrice}<br/>{m}
    </a>
  }
      {persons >= 2 && 
        <a className="product-price p2" href={`https://api.whatsapp.com/send?phone=${bizNUM}&text=${wspMsgStart} ${meal} ${name} ${b}.`}>
          ₪{bPrice}<br/>{category === 'בורגרים 🍔' ? b : bNoBurger}
        </a>
      }
  
  
      {persons === 4 && 
        <a className="product-price p4" href={`https://api.whatsapp.com/send?phone=${bizNUM}&text=${wspMsgStart} ${meal} ${name} ${xb}.`}>
          ₪{xbPrice}<br/>{xb}
        </a>
      }
    </div>
  );

  return (
    <div className={`modal`} onClick={onClose}>
      <div className={`modal-content`} data-aos={`zoom-in`} data-aos-duration="800">
        <img src={`${endPoint}/logo-b.svg`} width={75} alt="" style={{ marginBottom: '15px' }} />
        <h2 className="product-title">{name}</h2>
        <p className="product-desc">{desc}</p>
        {persons > 1 ? notIndividualPrices : <p className="product-price">₪{price}</p>}
        <Link to={`/${category.slice(0, -3)}/${sku}`}>
          <img className='product-image' src={`${endPoint}${defaultPic}`} alt={`${name}`} style={{ borderRadius: '15px', marginBottom: '25px' }} />
        </Link>
        <div className="modal-buttons">
          <a href={`https://api.whatsapp.com/send?phone=${bizNUM}&text=${wspMsgStart} ${name}.`}>
            <RiWhatsappFill size={20} className='svgs' />{orderBtn}</a>
          <a href={`whatsapp://send?text=${name} - ${price}₪ - ${url}${category.slice(0, -3)}/${sku}`} data-action="share/whatsapp/share" rel="noreferrer" target="_blank" >
            <FaShare size={20} className='svgs' />{shareBtn}</a>
        </div>
      </div>
    </div>
  );
};

export default ProductModal