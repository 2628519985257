// App.js
import React from 'react';
import './App.css';
import LanguageContext from './contexts/LanguageSwitcher';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Home from './pages/Home';
import Products from './pages/Products';
import ScrollToTop from './utils/ScrollToTop';
import { endPoint } from './utils/Utils';
import Product from './pages/Product';
// import Modal from './components/Modal/Modal';
import heContent from "./languages/hebrew.json"
import heTakeAwayContent from "./languages/takeaway-hebrew.json"
import arTakeAwayContent from "./languages/takeaway-arabic.json"
import arContent from "./languages/arabic.json"

const App = () => {
    AOS.init({
        offset: 200,
    });

    const { language } = React.useContext(LanguageContext);
    // const [choice, setChoice] = useState("Dine-in"); // Managing choice state here
    let choice = "Dine-in"

    // Function to handle user choice (Take Away or Eat-in)
    // const handleChoice = (selectedChoice) => {
    //     setChoice(selectedChoice);
    // };

    let contentLng
    choice === "TakeAway" ?
    contentLng = language === 'Ar' ? arTakeAwayContent : heTakeAwayContent
    :
    contentLng = language === 'Ar' ? arContent : heContent

    return (
        <div className='App' style={{ textAlign: "center", fontFamily: language === 'He' ? 'Heebo' : 'Cairo' }}>
            <Router>
                <ScrollToTop />
                {/* Show Modal for user to choose between "Take Away" or "Eat-in" */}
                {/* {!choice && <Modal onChoice={handleChoice} />}  */}

                {/* {choice && ( */}
                    <Routes>
                        <Route 
                            exact path="/" 
                            element={<Home endPoint={endPoint} contentLng={contentLng} choice={choice} />} />
                        <Route 
                            path='/:categoryName/:productId' 
                            element={<Product endPoint={endPoint} contentLng={contentLng} choice={choice} />} />
                        <Route 
                            path='/:categoryName' 
                            element={<Products endPoint={endPoint} contentLng={contentLng} choice={choice} />} />
                    </Routes>
                {/* )} */}
            </Router>
        </div>
    );
}

export default App;