// export const endPoint = "https://qbmedia.b-cdn.net/luqmaimages"
export const endPoint = "https://qbmedia.b-cdn.net/risto/"
export const url = "https://dineinmenu.risto.co.il/"
export const url2 = "https://takeawaymenu.risto.co.il/"

export const bizNUM = '97246064333'
export const bizNUM2 = '046064333'
export const bizFB = 'risto steakhouse'
export const bizIG = 'risto.steakhouse'
export const bizWEB = 'risto.co.il'

// Define different prices for eat-in menu
export const eatInPrices = {
    // Salads
    "vegetable-salad": 20,
    "red-cabbage-salad": 15,
    "white-cabbage-salad": 15,
    "corn": 20,
    "tomato-with-spicy": 15,
    "tabbouleh": 20,
    "tahini": 15,
    // Pitot
    "antricot-pita": 38,
    "kebab-pita": 30,
    "pargiyot-pita": 28,
    "lamb-pita": 40,
    // Tortillas
    "kebab-tortilla": 30,
    "chicken-breast-tortilla": 30,
    "pargiyot-tortilla": 30,
    "schnitzel-tortilla": 30,
    "lamb-tortilla": 43,
    "antricot-tortilla": 40,
    "business-meal-tortilla": 45,
    // Mains
    "chicken-wings-sweet-chili-sesame": 40,
    "small-onion-rings": 17,
};