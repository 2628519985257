import { Link, useParams } from "react-router-dom";
import { BottomNavbar, Footer, Navbar } from "../components";
import { RiWhatsappFill } from 'react-icons/ri';
import { FaShare } from 'react-icons/fa';
import {url, bizNUM, bizNUM2} from '../utils/Utils'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const Product = ({ endPoint, contentLng }) => {

    const { productId, categoryName } = useParams()
    const category = contentLng.categories.find((cat) => cat.name.slice(0, -3) === categoryName);
    const product = category.products.find((product) => product.sku === productId);
    const { Prenote, boldInNote, restOfNote,whatsappDetails: { wspMsgStart, shareBtn, orderBtn, meal, s, m, b, xb,sNoBurger, bNoBurger } } = contentLng
    const defaultPic = product.picture ? `${endPoint}/products/${product.picture}` : `${endPoint}/default.png`;
    const notIndividualPrices = (
        <div className='notIndividualPrices product-page'>
      {product.persons >= 1 && 
        <a className="product-price p1" href={`https://api.whatsapp.com/send?phone=${bizNUM}&text=${wspMsgStart} ${meal} ${product.name} ${s}.`}>
          ₪{product.price}<br/>{product.category === 'בורגרים 🍔' ? s : sNoBurger }
        </a>
      }
  
  {product.persons >= 3 && 
    <a className="product-price p3" href={`https://api.whatsapp.com/send?phone=${bizNUM}&text=${wspMsgStart} ${meal} ${product.name} ${m}.`}>
      ₪{product.mPrice}<br/>{m}
    </a>
  }
      {product.persons >= 2 && 
        <a className="product-price p2" href={`https://api.whatsapp.com/send?phone=${bizNUM}&text=${wspMsgStart} ${meal} ${product.name} ${b}.`}>
          ₪{product.bPrice}<br/>{product.category === 'בורגרים 🍔' ? b : bNoBurger}
        </a>
      }
      
      
          {product.persons === 4 && 
            <a className="product-price" href={`https://api.whatsapp.com/send?phone=${bizNUM}&text=${wspMsgStart} ${meal} ${product.name} ${xb}.`}>
              ₪{product.xbPrice}<br/>{xb}
            </a>
          }
        </div>
      );

    return (
        <>
    <Navbar endPoint={endPoint} />
    <div className='Product' style={productStyle}>
        <Link to={`/${categoryName}`}>
            <button style={{margin:'20px 15px'}} className='showAllBtn'>
                <ArrowForwardIosIcon />{categoryName}
            </button>
        </Link>
        <h1 style={{ marginTop: '30px' }}>{product.name}</h1>
        <p style={{padding:'0px 40px'}}>{product.desc}</p>
        {product.persons > 1 ? notIndividualPrices : 
            <div className="notIndividualPrices" style={{justifyContent:'center'}}> 
                <div className="product-price">₪{product.price}</div>
            </div>
        }
        <img src={`${defaultPic}`} style={{ margin: '10px 0px', height: '60vw', width: '75vw', borderRadius:'10px',objectFit:"cover" }} alt="Risto Steak House | ריסתו סטייק האוס" />
        
        {/* Add-ons Section */}
        {/* <div className="addons" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', padding: '20px 55px' }}>
            <div>
                <p>בצל מטוגן 3₪</p>
            </div>
            <div>
                <p>אקסטרה גבינה 5₪</p>
            </div>
            <div>
                <p>פלפל חריף 3₪</p>
            </div>
            <div>
                <p>פטריות 7₪</p>
            </div>
            <div>
                <p>ביצת עין 7₪</p>
            </div>
            <div>
                <p>מזרק גבינה 15₪</p>
            </div>
            <div>
                <p>כתף בקר 12₪</p>
            </div>
            <div>
                <p>אסאדו 15₪</p>
            </div>
        </div> */}

        {/* Modal Buttons */}
        <div className="modal-buttons">
            <a className="product-page-btns" href={`https://api.whatsapp.com/send?phone=${bizNUM}&text=${wspMsgStart} ${product.name}.`}>
                <RiWhatsappFill size={20} />{orderBtn}
            </a>
            <a className="product-page-btns" href={`whatsapp://send?text=${product.name} ב${product.price} ${url}${categoryName}/${productId}`} data-action="share/whatsapp/share" rel="noreferrer" target="_blank" >
                <FaShare size={20} />{shareBtn}
            </a>
        </div>

        <p style={{ padding: '35px', marginBottom: '50px' }}>{Prenote} 
            <a href={`tel:${bizNUM2}`} className="link">{boldInNote}</a> {restOfNote}
        </p>
    </div>
    <Footer contentLng={contentLng} />
    <BottomNavbar />
</>

    )
}

const productStyle = {
    height: 'auto',
    marginTop: '56px',
    backgroundColor:'#fff'
}

export default Product