
const Footer = ({contentLng}) => { 

return(

<div className='Footer' style={footerStyle}>
<p>{contentLng.devText} <a href="https://qbmedia.co.il" target="_blank" rel="noreferrer" style={footerStyle.a}>{contentLng.company}</a></p>
</div>

)
}

const footerStyle = {
    marginBottom:"56px",
    backgroundColor:"rgb(33 30 67)",
    height:"100px",
    color:"white",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    a:{
        fontWeight:"bold",
        textDecoration:"none",
        color:"#E9721E",
        pointer:"cursor"
    }
}

export default Footer